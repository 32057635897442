var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import { authInstance } from '../../firebase';
import * as segment from '../../utils/segment';
import { prepareOfflineTracking } from '../../utils/sw';
const initialState = {
    firebaseAuthInitiated: false,
    firebaseAuthTriggered: false,
    firebaseUser: undefined,
    firebaseIsNewUser: false,
    firebaseIDToken: undefined,
    firebaseIDTokenFetched: undefined,
    firebaseRemoteConfig: {
        webUseWebRTC: false,
        webEnableSSO: false,
        webEnableSolodia: false,
        soloDiaTimeout: 45,
        isTextToSpeechV2: false,
    },
};
export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setFirebaseUser(state, { payload }) {
            state.firebaseUser = payload;
            state.firebaseIsNewUser = payload.metadata.lastSignInTime === payload.metadata.creationTime;
        },
        markFirebaseAuthTriggered(state) {
            state.firebaseAuthTriggered = true;
        },
        markFirebaseLoginInitiated(state) {
            state.firebaseAuthInitiated = true;
        },
        markFirebaseLoginFinished(state) {
            state.firebaseAuthInitiated = false;
        },
        setFirebaseRemoteConfig(state, { payload }) {
            var _a, _b, _c, _d, _e, _f;
            state.firebaseRemoteConfig = {
                webUseWebRTC: ((_a = payload['web_use_webrtc']) === null || _a === void 0 ? void 0 : _a.asBoolean()) || false,
                webEnableSSO: ((_b = payload['web_enable_sso']) === null || _b === void 0 ? void 0 : _b.asBoolean()) || false,
                webEnableSolodia: ((_c = payload['web_enable_solodia']) === null || _c === void 0 ? void 0 : _c.asBoolean()) || false,
                soloDiaTimeout: ((_d = payload['modal_for_participant_amount_timer']) === null || _d === void 0 ? void 0 : _d.asNumber()) || 45,
                isTextToSpeechV2: ((_e = payload['is_text_to_speech_v2']) === null || _e === void 0 ? void 0 : _e.asBoolean()) || false,
                enableConvoV2: ((_f = payload['convo_v2']) === null || _f === void 0 ? void 0 : _f.asBoolean()) || false,
            };
        },
        clear(state) {
            Object.assign(state, initialState);
        },
    },
    extraReducers: (builder) => {
        builder.addCase(loadIDToken.fulfilled, (state, action) => {
            state.firebaseIDToken = action.payload;
            state.firebaseIDTokenFetched = Date.now();
        });
    },
});
export const authReducer = authSlice.reducer;
export const { setFirebaseUser, markFirebaseLoginInitiated, markFirebaseLoginFinished, markFirebaseAuthTriggered, setFirebaseRemoteConfig, clear, } = authSlice.actions;
// If the user is authenticated with their own email and password, we treat
// them as logged out until they verify their email. This is not true for
// users who logged in with Facebook though - their email will always be
// unverified and we are okay with it.
const shouldWaitForEmailVerification = (firebaseUser) => {
    var _a, _b, _c;
    if (!firebaseUser)
        return false;
    const isCredentialiedUser = ((_b = (_a = firebaseUser.providerData) === null || _a === void 0 ? void 0 : _a.length) !== null && _b !== void 0 ? _b : 0) === 1 && ((_c = firebaseUser.providerData[0]) === null || _c === void 0 ? void 0 : _c.providerId) === 'password';
    return firebaseUser.email && !firebaseUser.emailVerified && isCredentialiedUser;
};
export const handleFirebaseUser = (dispatch, user) => {
    const waitForVerification = shouldWaitForEmailVerification(user);
    if (!user || !user.uid || waitForVerification) {
        segment.track('Firebase User Logged Out');
        return;
    }
    segment.track('Firebase User Logged In', { handleFirebaseUser: true });
    dispatch(setFirebaseUser(user));
    localStorage.setItem('prefetch_me_uid', user.uid);
    dispatch(loadIDToken());
    localStorage.setItem('avaAccount', 'true');
    localStorage.setItem('isAnonymous', user.isAnonymous ? 'true' : 'false');
    Sentry.addBreadcrumb({
        category: 'auth',
        message: JSON.stringify(user),
        level: Sentry.Severity.Info,
    });
};
export const loadIDToken = createAsyncThunk('auth/getIDToken', (_, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const state = (yield thunkAPI.getState());
    // Firebase ID Tokens are valid for an hour
    if (state.auth.firebaseIDTokenFetched && state.auth.firebaseIDTokenFetched + 50 * 60 * 1000 < Date.now())
        return state.auth.firebaseIDToken;
    const idToken = state.auth.firebaseUser ? yield state.auth.firebaseUser.getIdToken() : undefined;
    if (idToken) {
        prepareOfflineTracking(idToken);
    }
    return idToken;
}));
export const initiateAnonymousFirebaseLogin = (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    dispatch(markFirebaseLoginInitiated());
    try {
        yield authInstance.signInAnonymously();
    }
    finally {
        dispatch(markFirebaseLoginFinished());
    }
});
export const initiateFirebaseLoginWithToken = (dispatch, loginToken) => __awaiter(void 0, void 0, void 0, function* () {
    dispatch(markFirebaseLoginInitiated());
    try {
        yield authInstance.signInWithCustomToken(loginToken);
    }
    finally {
        dispatch(markFirebaseLoginFinished());
    }
});
export const initiateFirebaseLoginWithProvider = (dispatch, provider) => __awaiter(void 0, void 0, void 0, function* () {
    dispatch(markFirebaseLoginInitiated());
    try {
        yield authInstance.signInWithPopup(provider);
    }
    finally {
        dispatch(markFirebaseLoginFinished());
    }
});
export const logout = (dispatch, doNotRefresh) => __awaiter(void 0, void 0, void 0, function* () {
    if (authInstance.currentUser && authInstance.currentUser.isAnonymous) {
        yield authInstance.currentUser.delete();
    }
    dispatch(clear());
    yield authInstance.signOut();
    if (doNotRefresh) {
        return;
    }
    // For some reason Firebase sometimes ends up in a state where without this
    // no-one can ever log in anymore. We can only do that if we will refresh, as those
    // get populated on page load.
    indexedDB.deleteDatabase('firebase-installations-database');
    indexedDB.deleteDatabase('firebaseLocalStorageDb');
    window.location.reload();
});
export const fetchSignInMethods = (dispatch, email) => __awaiter(void 0, void 0, void 0, function* () {
    return yield authInstance.fetchSignInMethodsForEmail(email);
});
export const getUserInstance = () => {
    return authInstance.currentUser;
};
export const loginWithEmailAndPassword = (email, password) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    try {
        yield authInstance.setPersistence('local');
        const response = yield authInstance.signInWithEmailAndPassword(email, password);
        if (!((_a = response === null || response === void 0 ? void 0 : response.user) === null || _a === void 0 ? void 0 : _a.emailVerified)) {
            return 'unverified';
        }
        else {
            return 'success';
        }
    }
    catch (error) {
        return error.code;
    }
});
export const sendResetPasswordEmail = (email) => __awaiter(void 0, void 0, void 0, function* () {
    yield authInstance.sendPasswordResetEmail(email);
});
export const createUserNameWithEmailAndPassword = (email, password) => __awaiter(void 0, void 0, void 0, function* () {
    yield authInstance.createUserWithEmailAndPassword(email, password);
});
export const signInWithPhoneNumber = (phone, appVerifier) => __awaiter(void 0, void 0, void 0, function* () {
    return yield authInstance.signInWithPhoneNumber(phone, appVerifier);
});
export const confirmationResult = (code) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const response = yield window.confirmationResult.confirm(code);
        yield authInstance.setPersistence('local');
        return 'success';
    }
    catch (error) {
        return error;
    }
});
